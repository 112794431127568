<template>
  <div>
    <div class="nav-wrapper">
      <div class="stage-title">
        {{ stageTitle }}
      </div>
      <div class="slot-content">
        <slot></slot>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapActions } from "vuex";
export default {
  props: {
    stageTitle: {
      type: String,
      required: true,
    },
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {},
  computed: {},
};
</script>
<style scoped>
* {
  font-family: Moderat;
  font-style: normal;
  font-weight: normal;
}
.nav-wrapper {
  background: #ffffff;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
  height: 64px;
  padding-left: 32px;
  padding-right: 32px;
  display: flex;
  justify-content: space-between;
  position: fixed;
  width: calc(100% - 200px);
  top: 0px;
  right: 0px;
  z-index: 700;
}
.stage-title {
  text-align: initial;
  padding-top: 22px;
  font-weight: 500;
  font-size: 14px;
  line-height: 130%;
  color: #1b1e22;
}
</style>