<template>
  <div>
    <div class="page-container">
      <div class="content-wrapper">
        <div class="user-greeting">Hi {{ shortenName(currentUser.name) }},</div>
        <div class="text-below">
          {{ $moment().format("dddd, Do MMM YYYY, h:mm:ss a") }}
        </div>
        <nylasPop />
        <div class="row">
          <div class="column">
            <div class="card card-1">
              <div class="card-text-block">
                <p style="font-weight: normal">
                  Get an Elite Product Engineering Team to bring your product
                  idea to life
                </p>
                <div @click="goToBuildAProduct()" class="button">
                  Build a Product
                </div>
              </div>
            </div>
          </div>
          <div class="column">
            <div class="card card-2">
              <div class="card-text-block">
                <h5>Refer & Earn</h5>
                <p class="p-2">
                  Refer your friends as Founder or a FAANG Expert and get up to
                  $1,250 for every successful referral.
                </p>
                <div class="button-plain">
                  Refer now <img src="/img/onboarding/right-arrow.svg" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="hangout-container">
          <div><img src="/img/onboarding/sip_cup.svg" /></div>
          <div class="hangout-bold-text">
            Hangout with other Founders and VCs!
          </div>
          <div class="hangout-light-text">
            Expand your network, bounce ideas, learn all you need to know about
            building amazing products.
          </div>
          <div @click="handleJoinConversation" class="join-conversation">
            Join the Conversation
            <img src="/img/onboarding/blue-arrow.svg" alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import shortName from "@/mixins/shortName";
import { UpdateTimeZone } from "@/api";
import nylasPop from "../../workspace/newProject/dashboard/nylasPop.vue";

export default {
  props: {},
  components: {
    nylasPop,
  },
  mixins: [shortName],
  data: () => ({
    isUsingVPN: true,
    browserTimezone: "",
    ipTimezone: "",
  }),
  created() {},
  mounted() {
    this.detectVPN()
      .then((detectionResult) => {
        console.log(detectionResult);

        if (detectionResult.usingVPN) {
          this.isUsingVPN = true;
        } else {
          this.isUsingVPN = false;
        }

        this.browserTimezone = detectionResult.browser;
        // this.ipTimezone = detectionResult.ip;

        // console.log("currentUser :: ", this.currentUser);
        // console.log("currentUser :2: ", this.currentUser.timezone);
        if (
          //JSON.stringify(this.currentUser.timezone) === undefined

          JSON.stringify(this.currentUser.timezone) === undefined ||
          JSON.stringify(this.currentUser.timezone) === null ||
          JSON.stringify(this.currentUser.timezone) == "null"
        ) {
          // alert('cleint')
          this.ConfirmTimeZone();
        }
      })
      .catch(function (err) {
        console.log(err);
      });
  },
  methods: {
    async ConfirmTimeZone() {
      const payload = {
        timezone: this.browserTimezone,
      };
      UpdateTimeZone(payload)
        .then((response) => {
          // console.log("UpdateTimeZone : ", response);
        })
        .catch((error) => {
          //alert(error);
        });
    },
    async detectVPN() {
      var browserTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

      return fetch(`https://ipapi.co/json`)
        .then(function (response) {
          return response.json();
        })
        .then(function (data) {
          var ipTimezone = data.timezone;
          /*  console.log(
            `browser timezone: ${browserTimezone}`,
            `ip timezone: ${ipTimezone}`
          ); */
          return {
            browser: browserTimezone,
            ip: ipTimezone,
            usingVPN: ipTimezone != browserTimezone,
          };
        });
    },
    goToBuildAProduct() {
      this.$router.push({ name: "start_project" });
    },
    goToRefer() {
      this.$router.push({ name: "start_project" });
    },
    handleJoinConversation() {
      this.$router.push({ name: "join_conversation" });
      // window.open(
      //   "https://join.slack.com/t/grupastartupc-o9l1950/shared_invite/zt-11zl6ug1d-UUPK2jfmAKlDdluLKL10DA",
      //   "_blank"
      // );
      this.mixPanelTrack({
        event: "ClientJoinConversation",
      });
    },
    // handleReferralNow(){
    //   this.$router.push({ name: "referral" });
    // }
  },
  computed: {
    ...mapState("userManagement", ["currentUser"]),
  },
};
</script>
<style scoped>
* {
  font-family: Moderat;
  font-style: normal;
  font-weight: normal;
}
.page-container {
  padding: 48px 32px 32px;
  margin-top: 67px;
  min-height: calc(100vh - 67px);
  background: #f7f7f8;
}
.user-greeting {
  font-weight: 500;
  font-size: 32px;
  line-height: 145%;
  color: #000000;
}
.text-below {
  font-size: 14px;
  line-height: 130%;
  color: #979da5;
  margin-top: 4px;
}
.content-wrapper {
  text-align: left;
}
.column {
  float: left;
  width: 50%;
  padding: 0 10px;
}

/* Remove extra left and right margins, due to padding in columns */
.row {
  margin: 34px -5px;
}

/* Clear floats after the columns */
.row:after {
  content: "";
  display: table;
  clear: both;
}

/* Style the counter cards */
.card {
  padding: 16px;
  text-align: center;
  background-color: #f1f1f1;
  height: 100%;
}
.card.card-1 {
  background: radial-gradient(56.56% 50% at 50% 50%, #23272c 0%, #15171a 100%);
  border: none;
  border: 4px;
  color: #fff;
}
.card.card-2 {
  background: #fbefee;
  color: #d45b54;
  border: none;
  height: 100%;
}
.card-text-block {
  width: 380px;
}
.card-text-block p {
  word-break: break-word;
  font-size: 18px;
  padding: 20px;
  text-align: left;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 145%;
  color: #ffffff;
}
.card-text-block p.p-2 {
  font-size: 14px;
  line-height: 145%;
  color: #d45b54;
  width: 266px;
  font-weight: normal;
}
.card-text-block h5 {
  text-align: left;
  padding-left: 7px;
  padding-bottom: 10px;
  font-weight: bold;
}
.card-text-block .button {
  word-break: break-word;
  font-size: 15px;
  width: 150px;
  padding: 5px;
  background: #ffffff;
  border-radius: 3px;
  color: #0781f2 !important;
  margin-left: 20px;
  cursor: pointer;
}
.card-text-block .button-plain {
  word-break: break-word;
  font-size: 15px;
  width: 150px;
  padding: 5px;
  border-radius: 3px;
  color: #0781f2 !important;
  text-align: left;
  cursor: pointer;
}
.button-plain img {
  fill: #0781f2;
}
.hangout-container {
  background: #ffffff;
  border-radius: 4px;
  margin-top: 16px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: 515px;
}
.hangout-bold-text {
  font-weight: bold;
  font-size: 18px;
  line-height: 145%;
  text-align: center;
  color: #1b1e22;
  margin-top: 24px;
}
.hangout-light-text {
  font-weight: normal;
  font-size: 14px;
  line-height: 145%;
  text-align: center;
  color: #53585f;
  margin-top: 12px;
  width: 420px;
}
.join-conversation {
  margin-top: 24px;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #0781f2;
  cursor: pointer;
}
/* Responsive columns - one column layout (vertical) on small screens */
@media screen and (max-width: 600px) {
  .column {
    width: 100%;
    display: block;
    margin-bottom: 20px;
  }
  .hangout-light-text {
    width: 100%;
  }
}
</style>
