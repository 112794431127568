var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "page-container" }, [
      _c(
        "div",
        { staticClass: "content-wrapper" },
        [
          _c("div", { staticClass: "user-greeting" }, [
            _vm._v("Hi " + _vm._s(_vm.shortenName(_vm.currentUser.name)) + ","),
          ]),
          _c("div", { staticClass: "text-below" }, [
            _vm._v(
              "\n        " +
                _vm._s(_vm.$moment().format("dddd, Do MMM YYYY, h:mm:ss a")) +
                "\n      "
            ),
          ]),
          _c("nylasPop"),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "column" }, [
              _c("div", { staticClass: "card card-1" }, [
                _c("div", { staticClass: "card-text-block" }, [
                  _c("p", { staticStyle: { "font-weight": "normal" } }, [
                    _vm._v(
                      "\n                Get an Elite Product Engineering Team to bring your product\n                idea to life\n              "
                    ),
                  ]),
                  _c(
                    "div",
                    {
                      staticClass: "button",
                      on: {
                        click: function ($event) {
                          return _vm.goToBuildAProduct()
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n                Build a Product\n              "
                      ),
                    ]
                  ),
                ]),
              ]),
            ]),
            _vm._m(0),
          ]),
          _c("div", { staticClass: "hangout-container" }, [
            _vm._m(1),
            _c("div", { staticClass: "hangout-bold-text" }, [
              _vm._v(
                "\n          Hangout with other Founders and VCs!\n        "
              ),
            ]),
            _c("div", { staticClass: "hangout-light-text" }, [
              _vm._v(
                "\n          Expand your network, bounce ideas, learn all you need to know about\n          building amazing products.\n        "
              ),
            ]),
            _c(
              "div",
              {
                staticClass: "join-conversation",
                on: { click: _vm.handleJoinConversation },
              },
              [
                _vm._v("\n          Join the Conversation\n          "),
                _c("img", {
                  attrs: { src: "/img/onboarding/blue-arrow.svg", alt: "" },
                }),
              ]
            ),
          ]),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "column" }, [
      _c("div", { staticClass: "card card-2" }, [
        _c("div", { staticClass: "card-text-block" }, [
          _c("h5", [_vm._v("Refer & Earn")]),
          _c("p", { staticClass: "p-2" }, [
            _vm._v(
              "\n                Refer your friends as Founder or a FAANG Expert and get up to\n                $1,250 for every successful referral.\n              "
            ),
          ]),
          _c("div", { staticClass: "button-plain" }, [
            _vm._v("\n                Refer now "),
            _c("img", { attrs: { src: "/img/onboarding/right-arrow.svg" } }),
          ]),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("img", { attrs: { src: "/img/onboarding/sip_cup.svg" } }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }