<template>
  <div>
    <Toolbar stageTitle=" Overview" />
    <Overview />
  </div>
</template>

<script>
import Toolbar from "@/components/toolbar/generalToolBar";
import Overview from "./dashboard";
export default {
  components: {
    Overview,
    Toolbar,
  },
  data() {
    return {
      user: {},
    };
  },
  methods: {},
  computed: {},
};
</script>

<style scoped>
</style>


